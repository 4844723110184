<template>

  <component
    :is="component.component"
    v-for="component of parts"
    :id="component.slug || component.data.slug"
    :key="component.id || component.data.id"
    :payload="component.data || component"
  />

</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {

    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),
    Homepage: defineAsyncComponent(() => import('@/components/pages/Homepage.vue')),
    Architektur: defineAsyncComponent(() => import('@/components/parts/Architektur.vue')),
    Innenarchitektur: defineAsyncComponent(() => import('@/components/parts/Innenarchitektur.vue')),
    Immobilien: defineAsyncComponent(() => import('@/components/parts/Immobilien.vue')),
    Kontakt: defineAsyncComponent(() => import('@/components/parts/Kontakt.vue')),

  },
  props: { inject: { type: Object, default: Object } },
  computed: {
    parts() {
      return this.inject.filter((part) => !part.data?.hidden);
    },
  },
};
</script>
