<template>

  <div id="overlay" />

  <Navigation />

  <main>
    <Dynamic v-if="content" :inject="content" />
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/hooks/dynamicPage';

export default {
  components: {

    Navigation,
    Dynamic,

  },

  setup() {
    dynamicPage();
  },

  computed: {
    content() {
      return this.$store.state.router.page?.content;
    },
  },

};
</script>
