<template>
  <div id="nav" ref="nav">
    <nav class="inner">
      <Go
        v-for="link of navigation?.links"
        :key="link"
        :to="link.anchor"
        class="px-4 link"
        v-html="link.title"
      />
    </nav>
  </div>

  <Hamburger
    class="burger lg:hidden"
    :class="{ active: mobile }"
    @click="toggle()"
  />

  <div class="movile-wrapper">
    <div class="backdrop" :class="{ active: mobile }" />
    <transition-group name="appear">
      <div v-if="mobile" class="mobile-nav">
        <Go
          v-for="link of navigation?.links"
          :key="link.id"
          :to="link.anchor"
          class="px-4 link"
          @click="mobile = false"
          v-html="link.title"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import Language from '../utils/Language.vue';
import Hamburger from '../utils/Hamburger.vue';

export default {
  components: {
    Language,
    Hamburger,
  },
  data: () => ({
    mobile: false,
  }),
  computed: {
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
  },
  mounted() {
    // this.onScroll();
    // document.addEventListener('scroll', this.onScroll, { passive: true });
  },
  unmounted() {
    document.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    toggle(close) {
      if (close === undefined) close = this.mobile;
      if (close) this.mobile = false;
      else this.mobile = true;
    },
    onScroll() {
      this.$refs.nav.style.top = `${Math.max(window.scrollY, window.innerHeight)}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
#nav {
  position: fixed;
  z-index: 100;
  top: 3rem;
  left: 2rem;

  @screen xl {
    top: 7rem;
    left: 7rem;
  }

  mix-blend-mode: difference;
  color: white;

  .inner {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: theme("screens.lg")) {
    padding-top: 20px;
    .inner {
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 0;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  }
}

.link {
  font-size: 1.3rem;
  line-height: 1.3;

  &.active {
    text-decoration: underline;
  }

  @include hover {
    text-decoration: underline;
  }
}

/* MOBILE NAV */
.burger {
  color: black;
  position: fixed;
  z-index: 1000;
  top: 2rem;
  left: 2rem;
  cursor: pointer;
  @screen md {
    top: 4rem;
    left: 4rem;
  }
}

.backdrop {
  content: "";
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  background: rgba(255, 255, 255, 0);
  transition: 0.5s ease;
  pointer-events: none;
  &.active {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    pointer-events: all;
  }
}

.mobile-nav {
  position: fixed;
  overflow-y: scroll;

  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;

  .link {
    font-size: 1.7rem;
    color: black;
  }

  a {
    padding: 0.6rem 10vw;
    text-decoration: none !important;
  }
}
</style>
